<template>
    <div>
        <FilterComponent @selectedFilter="applyFilters($event)" />

        <b-card no-body class="mb-0">
            <b-overlay :show="isFetchingTextAppListSubscribersArray" variant="transparent" opacity="1" blur="2px"
                rounded="sm">
                <b-table ref="refUserListTable" class="position-relative p-1" :items="textappSubscribersListArray"
                    responsive :fields="tableColumnsFieldsArray" primary-key="id" show-empty
                    empty-text="No record found">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>

                    <template #cell(phone_number)="data">
                        {{ safeDecodeURIComponent(data.item.phone_number) }}
                    </template>

                    <template #cell(created_at)="data">
                        <span>{{ data.item.created_at | moment('MMM DD, YYYY') }}</span>
                    </template>
                </b-table>

                <!-- Load More Button with Visibility Check -->
                <b-row v-if="ifRecordFound && textappSubscribersListArray.length > 0 && !hideLoadMoreButton">
                    <b-col cols="12 mb-1 d-flex justify-content-center">
                        <b-button type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            @click="loadMoreRecords">
                            Load More
                        </b-button>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import dateTypeListOptions from "@/libs/dateTypeListOptions";
import {
    BCard, BRow, BCol, BButton, BTable, BOverlay
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import FilterComponent from './Filter.vue';

export default {
    components: {
        BCard, BRow, BCol, BButton, BTable, BOverlay, FilterComponent
    },
    directives: { Ripple },
    data() {
        return {
            isFetchingTextAppListSubscribersArray: false,
            tableColumnsFieldsArray: [
                { key: 'index', label: '#' },
                { key: 'phone_number', label: 'Phone Number' },
                { key: 'created_at', label: 'Subscribed Date' },
            ],
            textappSubscribersListArray: [],
            selectedFilters: {}, /** Holds selected filter data  */
            records_start: 0, /** Track pagination start index */
            records_total: 0, /** Total number of records  */
            ifRecordFound: true,
            hideLoadMoreButton: false,
        };
    },

    methods: {
        /** Load more records */
        loadMoreRecords() {
            let dateTypeCustom = this.selectedFilters.date_type_custom_filter
                ? JSON.parse(this.selectedFilters.date_type_custom_filter)
                : {};

            const filterParams = {
                dateType: this.selectedFilters.date_type ?? '',
                fromDate: dateTypeCustom.start ?? '',
                toDate: dateTypeCustom.end ?? '',
                phoneNumber: this.selectedFilters.phoneNumber ?? '',
            };
            this.getTextAppSubscribersList(filterParams);
        },

        /** Fetch data with filters */
        getTextAppSubscribersList(selectedFilter) {
            this.isFetchingTextAppListSubscribersArray = true;

            this.$textAppSubscribersAPI.post('fetch-textapp-subscribers-list', {
                'siteId': this.$root.appSiteObject.siteId ?? '',
                'start': this.records_start,
                'total_page': 20,
                'phoneNumber': selectedFilter.phoneNumber ?? '',
                'dateType': selectedFilter.dateType ?? '',
                'fromDate': selectedFilter.fromDate ?? '',
                'toDate': selectedFilter.toDate ?? '',
            }).then((response) => {
                response = response.data;

                if (response.status) {
                    this.textappSubscribersListArray.push(...response.textAppListData);
                    this.records_start += response.textAppListData.length;
                    this.records_total = response.totalRecords;
                    this.hideLoadMoreButton = this.records_start >= this.records_total;
                    this.ifRecordFound = response.textAppListData.length > 0;
                } else {
                    this.ifRecordFound = false;
                    this.hideLoadMoreButton = true;
                }

                this.isFetchingTextAppListSubscribersArray = false;
            }).catch(() => {
                this.isFetchingTextAppListSubscribersArray = false;
                this.ifRecordFound = false;
                this.hideLoadMoreButton = true;
            });
        },

        /** Safely decode URI */
        safeDecodeURIComponent(value) {
            try {
                return decodeURIComponent(value);
            } catch (e) {
                return value;
            }
        },

        /** Apply filters */
        applyFilters(filterObject) {
            this.records_start = 0;
            this.textappSubscribersListArray = [];
            this.hideLoadMoreButton = false;
            this.selectedFilters = { ...filterObject };

            let dateTypeCustom = filterObject.date_type_custom_filter ? JSON.parse(filterObject.date_type_custom_filter) : {};
            const filterParams = {
                dateType: filterObject.date_type,
                fromDate: dateTypeCustom.start ?? '',
                toDate: dateTypeCustom.end ?? '',
                phoneNumber: filterObject.phone_number ?? '',
            };
            this.getTextAppSubscribersList(filterParams);
        },
    },
    created() { },
    setup() {

        const {
            dateTypeOptions,
        } = dateTypeListOptions()

        return {
            dateTypeOptions,
        }
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
