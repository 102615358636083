<template>
    <b-card no-body class="card-statistics">
        <b-overlay :show="isFetchingTextAppListSubscribersArray" :variant="'transparent'" :opacity="'1'" :blur="'2px'"
            rounded="sm">
            <b-card-header>
                <b-card-title>Filters</b-card-title>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col class="m-50" xl="3" sm="12">
                        <b-form-input v-model="phoneNumber" type="tel" placeholder="Search Phone Number"
                            @input="fetchCountStatisticsItems" />
                    </b-col>
                    <b-col class="m-50" xl="2" sm="12">
                        <Select2 v-model="dateTypeValue" :options="dateTypeOptions"
                            @change="selectDateTypeChangeEvent($event)" />
                    </b-col>
                    <b-col class="m-50" xl="3" sm="12" v-if="dateTypeValue == 'custom_date'">
                        <VueCtkDateTimePicker id="customDateTypeFilter" v-model="customDateTypeFilter" :overlay="true"
                            :noShortcuts="true" :range="true" :onlyDate="true" :time="false" format="MM/DD/YYYY"
                            formatted="ll" @is-hidden="changeCustomDateEvent" />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-overlay>
    </b-card>
</template>
<script>
import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BFormInput
} from 'bootstrap-vue';

import dateTypeListOptions from "@/libs/dateTypeListOptions";

export default {
    components: {
        BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody, BOverlay, BFormInput
    },
    props: {
    },
    data() {
        return {
            isFetchingTextAppListSubscribersArray: false,
            dateTypeValue: 'all_time',
            customDateTypeFilter: '',
            phoneNumber: '',
        }
    },
    beforeCreate: function () {
    },
    watch: {},
    created() {
        this.getDefaultFilterElements(); /* FETCH FILTER FROM VUEX */
    },
    mounted() {
        this.fetchCountStatisticsItems();
    },
    computed: {
    },
    methods: {
        selectDateTypeChangeEvent(val) {
            this.dateTypeValue = val;
            /* TO CHECK CUSTOM DATE OR NOT : START */
            if (this.dateTypeValue == 'custom_date') {
                this.customDateTypeFilter = '';
            } else {
                this.fetchCurrentFilterRecords();
            }
            /* TO CHECK CUSTOM DATE OR NOT : END */

            if (val != "custom_date") {
                /* STORE FILTER IN VUEX AND DB : START */
                this.$root.setDefaultFilterElements({
                    page: 'textAppSubscriber',
                    filterObject: {
                        'search_id': val
                    },
                });
                /* STORE FILTER IN VUEX AND DB : END */    
            }
        },
        changeCustomDateEvent() {
            if (this.customDateTypeFilter.start && this.customDateTypeFilter.end) {
                this.fetchCountStatisticsItems();

                /* STORE FILTER IN VUEX AND DB : START */
                this.$root.setDefaultFilterElements({
                    page: 'textAppSubscriber',
                    filterObject: {
                        'search_id': this.dateTypeValue,
                        'date_type': this.dateTypeValue,
                        'date_type_custom_filter': this.customDateTypeFilter,
                    },
                });
                /* STORE FILTER IN VUEX AND DB : END */
            } else {
                this.customDateTypeFilter = '' /* IF ANY START OR END DATE IS EMPTY */
            }
        },
        fetchCurrentFilterRecords() {
            this.fetchCountStatisticsItems();
        },
        fetchCountStatisticsItems() {

            this.$emit('selectedFilter', {
                'date_type': this.dateTypeValue,
                'date_type_custom_filter': JSON.stringify(this.customDateTypeFilter),
                'phone_number': this.phoneNumber,
            });

        },


        /**
        * GET FILTER FROM VUEX AND SET INTO ELEMENTS
        */
        getDefaultFilterElements() {
            let filterObject = this.$store.getters['appModuleFilters/getFilter']({
                page: 'textAppSubscriber',
            });
            /* FETCH FILTER FROM VUEX : END */
            this.dateTypeValue = filterObject.search_id || 'all_time';
            this.customDateTypeFilter = filterObject.date_type_custom_filter;
        }
    },
    setup() {

        const {
            dateTypeOptions,
        } = dateTypeListOptions()

        return {
            dateTypeOptions
        }
    },
}
</script>